import TitleLine from "components/ui/TitleLine";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { useCart } from "components/ui/CartContext";
import axiosClient from "utils/axiosClient";
type Props = {
  initTitle?: string;
  type?: string;
};

function formatPrice(price: number): string {
  return price
    .toLocaleString("vi-VN", { style: "currency", currency: "VND" })
    .replace(/\./g, ",");
}

export default function ProductPage({ initTitle, type }: Props) {
  let [products, setData] = useState<any[]>([]);
  let [limit, setLimit] = useState(0);
  let [title, setTitle] = useState(
    initTitle == undefined ? "Tất cả sản phẩm" : initTitle
  );

  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const pageParam = query.page;
  const page = typeof pageParam === "string" ? parseInt(pageParam, 10) : 1;
  const typeParam = typeof query.type === "string" ? query.type : "";
  const sortOption =
    query.sort != "" && typeof query.sort === "string"
      ? query.sort
      : "createdAt.desc";

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (type === "demo") {
          response = await axiosClient.get(`/product?page=${page}&limit=8`);
        } else {
          response = await axiosClient.get(
            `/product?page=${page}&limit=12&type=${typeParam}&sort=${sortOption}`
          );
        }
        setData(response.data.docs);
        setLimit(response.data.totalPages);
        const response2 = await axiosClient.get(`/type-product/${typeParam}`);
        if (typeParam != "") setTitle(response2.data.name);
        else setTitle("Tất cả sản phẩm");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [window.location.href]);
  console.log("hehehehehe");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(`/type-product/${typeParam}`);
        if (typeParam != "") setTitle(response.data.name);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [typeParam]);

  const { addToCart } = useCart();
  const history = useHistory();

  if (type == "demo") {
    products = products.slice(0, 8);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  const productClick = (productId: string) => {
    history.push(`/products/${productId}`);
    window.location.reload();
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber == page) return;
    history.push(
      `/products?page=${pageNumber}&type=${typeParam}&limit=12&sort=${sortOption}`
    );
  };

  const handleSortOptionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    history.push(
      `/products?page=${page}&type=${typeParam}&limit=12&sort=${event.target.value}`
    );
  };

  return (
    <div className="w-full flex-1">
      <TitleLine title={title}></TitleLine>
      <div
        className={`flex justify-end items-center mb-5 ${type == "demo" ? "hidden" : ""}`}
      >
        <label htmlFor="sortOption" className="font-bold">
          Sắp xếp theo:
        </label>
        <select
          className="ml-2 p-2 rounded-md border border-black shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          id="sortOption"
          value={sortOption}
          onChange={handleSortOptionChange}
        >
          <option value="createdAt.desc">Hàng mới</option>
          <option value="name.asc">Tên A - Z</option>
          <option value="name.desc">Tên Z - A</option>
          <option value="salePrice.asc">Giá tăng dần</option>
          <option value="salePrice.desc">Giá giảm dần</option>
        </select>
      </div>
      <div
        className={`w-full h-[400px] flex items-center justify-center text-lg ${products.length != 0 ? "hidden" : ""}`}
      >
        {" "}
        Không có sản phẩm nào{" "}
      </div>
      <div className="w-full grid lg:grid-cols-4 phone:grid-cols-2 grid-cols-1 gap-4 bg-white">
        {products.map((product: any) => (
          <div className="w-full flex flex-col bg-[#E8DEDE] rounded-lg text-base">
            <img
              src={product.images[0]}
              className="h-2/3 cursor-pointer"
              alt="logo"
              onClick={() => productClick(product.id)}
            />
            <div className="h-full flex flex-col p-4 justify-between py-4">
              <p
                className="cursor-pointer"
                onClick={() => productClick(product.id)}
              >
                {/* {product.name} */}
                {product.name.length > 80 ? (
                  <>{product.name.slice(0, 80 - 3)}...</>
                ) : (
                  <>{product.name}</>
                )}
              </p>

              <div className="flex flex-col">
              {/* <img src={discount} className="w-1/2 h-[40px]"></img> */}
              <div className="flex flex-row justify-between items-center">
                <div>
                  <span className="text-custom-red-500 text-2xl">
                    {formatPrice(product.salePrice)}
                  </span>{" "}
                  <br />
                  <del className="text-gray text-base">
                    {formatPrice(product.originPrice)}
                  </del>
                </div>

                <p
                  className="h-6 w-6 text-2xl text-custom-red-500 rounded-full border-2 
                  border-custom-red-500 flex items-center justify-center cursor-pointer"
                  onClick={() =>
                    addToCart({
                      image: product.images[0],
                      id: product.id,
                      name: product.name,
                      price: product.salePrice,
                      quantity: 1,
                    })
                  }
                >
                  +
                </p>
              </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`flex justify-center mt-4 ${type == "demo" || products.length == 0 ? "hidden" : ""}`}
      >
        <div className="flex justify-center my-4">
          {Array.from({ length: limit }, (_, i) => i + 1).map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageClick(pageNumber)}
              className={`
                mx-2 px-3 py-1 rounded-full transition-colors
                ${
                  pageNumber === page
                    ? "bg-red-500 text-white"
                    : "hover:bg-gray-300 hover:text-gray-700"
                }
              `}
            >
              {pageNumber}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
