import React, { useState, useEffect } from 'react';
import logo from 'assets/img/product.png'
import { useCart } from 'components/ui/CartContext';
import { Link } from 'react-router-dom';
import { PATH_NAME } from 'constants/pathName';
import {
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';

function formatPrice(price: number): string {
  return price.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' }).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const CartPage: React.FC = () => {
  const { cart, addToCart, removeFromCart, getTotalPrice } = useCart();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (

    <div className='h-fit w-full bg-[#EEEEEE] py-[30px] min-[1400px]:flex min-[1400px]:flex-col min-[1400px]:items-center'>
      <div className=''>
        <div className='h-fit bg-white px-[20px] py-[30px] flex flex-col'>
            <p className='text-xl font-bold mb-[30px]'>GIỎ HÀNG</p>
            <div className='overflow-x-auto'>
              <table className="w-[1200px] border-collapse overflow-x-auto">
                <thead>
                  <tr className="border-b border-t border-gray-300 bg-[#EEEEEE]">
                    <th className="p-2 border-r border-l border-gray-300">Hình ảnh</th>
                    <th className="p-2 border-r border-gray-300 w-2/6">Sản phẩm</th>
                    <th className="p-2 border-r border-gray-300">Giá bán</th>
                    <th className="p-2 border-r border-gray-300">Số lượng</th>
                    <th className="p-2 border-r border-gray-300">Tạm tính</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map((product, index) => (
                    <tr key={product.id} className={`border-b ${
                      index % 2 === 1 ? 'bg-[#EEEEEE]' : 'bg-white'
                    }`}>
                      <td className="py-2 border-r border-gray-300 border-l flex items-center justify-center"><img src={product.image} alt={product.name} style={{ width: '75px', height: '75px' }} /></td>
                      <td className="p-2 border-r border-gray-300">{product.name}</td>
                      <td className="p-2 border-r border-gray-300 text-center">{formatPrice(product.price)}</td>
                      <td className="p-2 border-r border-gray-300">
                        <div className=' flex items-center justify-center'>
                          <button 
                            onClick={() => removeFromCart(product.id)}
                            className="bg-red-500 ml-2 hover:bg-red-600 text-white font-bold p-2 rounded-lg">
                              <CloseOutlined/>
                          </button>
                          <div className="bg-white ml-2 py-2 w-[50px] text-center rounded-lg border border-black">
                            {product.quantity}
                          </div>
                          <button 
                            onClick={() => addToCart({image: product.image, id: product.id, name: product.name, price: product.price, quantity: 1 })}
                            className="bg-green-500 ml-2 hover:bg-green-600 text-white font-bold p-2 rounded-lg">
                              <PlusOutlined/>
                          </button>
                        </div>
                      </td>
                      <td className="p-2 border-r border-gray-300 text-center">{formatPrice(product.price * product.quantity)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          
          <div className='p-6 border-t-black font-bold flex justify-end'>
          Tổng cộng: {formatPrice(getTotalPrice())}
          </div>
        </div>

        <div className='h-fit w-full mt-[30px] text-lg flex flex-row justify-between'>
          <Link to={PATH_NAME.PRODUCTS}>
            <button className="bg-green-500 text-white px-4 py-2 rounded focus:outline-none">
              Tiếp tục mua hàng
            </button>
          </Link>
          <Link to={PATH_NAME.PAYMENT}>
            <button className="bg-green-500 text-white px-4 py-2 rounded focus:outline-none">
              Thanh toán
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
