import titleLogo from "assets/img/logo_new.png";
import axios from "axios";
import Type from "components/news/blog";
import TitleLine from "components/ui/TitleLine";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import axiosClient from "utils/axiosClient";

type Props = {
  type?: string;
};

export default function NewsPage({ type }: Props) {
  let [news, setData] = useState<any[]>([]);
  let [limit, setLimit] = useState(0);

  const location = useLocation();
  const history = useHistory();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const pageParam = query.page;
  const page = typeof pageParam === "string" ? parseInt(pageParam, 10) : 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(`/news?page=${page}`);
        setData(response.data.docs);
        setLimit(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [[page]]);

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber == page) return;
    history.push(`/news?page=${pageNumber}`);
    window.location.reload();
  };

  if (type == "demo") {
    news = news.slice(0, 3);
  }

  return (
    <div className="w-full flex-1">
      <TitleLine title="Tin tức mới"></TitleLine>
      <div className="">
        <div className="px-1">
          <div className="flex flex-wrap">
            {news.map((item, index) => (
              <Type
                key={index}
                srcImg={item.thumbnailImage}
                srcHref={"news/" + item.id}
                TypeTitle={item.title}
                TypeDesc={item.subTitle}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={`flex justify-center mt-4 ${type == "demo" ? "hidden" : ""}`}
      >
        <div className="flex justify-center my-4">
          {Array.from({ length: limit }, (_, i) => i + 1).map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageClick(pageNumber)}
              className={`
                mx-2 px-3 py-1 rounded-full transition-colors
                ${
                  pageNumber === page
                    ? "bg-red-500 text-white"
                    : "hover:bg-gray-300 hover:text-gray-700"
                }
              `}
            >
              {pageNumber}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
