import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import axiosClient from "utils/axiosClient";
type Props = {};

export default function NewsDetail({}: Props) {
  const { id } = useParams<{ id: string }>();
  let [newsDetail, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(`/news/${id}`);
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  console.log(newsDetail);
  return (
    <div className="w-full h-full flex flex-col flex-1">
      <h1 className="text-2xl font-bold text-custom-red-500">
        {newsDetail.title}
      </h1>
      <div
        className="leading-loose"
        dangerouslySetInnerHTML={{ __html: newsDetail.content }}
      />
    </div>
  );
}
