import React, { useState, useEffect } from "react";
import { useCart } from "components/ui/CartContext";
import { Link, useHistory } from "react-router-dom";
import { PATH_NAME } from "constants/pathName";
import { Button, Form, Input, Select } from "antd";
import axios from "axios";

function formatPrice(price: number): string {
  return price
    .toLocaleString("vi-VN", { style: "currency", currency: "VND" })
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const PaymentPage: React.FC = () => {
  const { cart, getTotalPrice } = useCart();
  const [form] = Form.useForm();
  const totalQuantity = cart.reduce((total, item) => total + 1, 0);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const handleSubmit = async () => {
    try {
      // Lấy thông tin từ form
      const values = await form.validateFields();

      // Đóng gói thông tin đơn hàng
      const requestBody = {
        customerName: values.name,
        customerPhone: values.phone,
        customerAddress: values.address,
        customerEmail: values.email,
        orderProducts: cart.map((item) => ({
          id: item.id,
          name: item.name,
          quantity: item.quantity, // Lấy quantity từ item
          price: item.price,
          total: item.quantity * item.price, // Tính tổng tiền cho từng sản phẩm
        })),
        total: getTotalPrice(), // Tính tổng tiền đơn hàng
      };
      // Gửi request POST lên API /orders
      await axios.post("/order", requestBody);
      setShowPopup(true);
    } catch (error) {
      console.error("Lỗi khi tạo đơn hàng:", error);
    }
  };

  const Popup = () => {
    const history = useHistory();

    const handleContinueShopping = () => {
      setShowPopup(false);
      history.push("/products");
    };

    const handleGoHome = () => {
      setShowPopup(false);
      history.push("/");
    };

    return (
      <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-4">
            Đơn hàng đã được tạo thành công!
          </h2>
          <div className="flex justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              onClick={handleContinueShopping}
            >
              Tiếp tục mua hàng
            </button>
            <button
              className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
              onClick={handleGoHome}
            >
              Quay về trang chủ
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="h-full bg-white min-[1200px]:flex min-[1200px]:justify-center">
      <div className="h-full bg-[#EEEEEE] min-[600px]:px-[80px] px-[5px] py-[30px]">
        <p className="text-xl font-bold mb-3">THÔNG TIN THANH TOÁN</p>
        <Form
          form={form}
          layout="vertical"
          className="w-full grid min-[600px]:grid-cols-2 grid-cols-1 gap-6"
          requiredMark={false}
          initialValues={{ payment_method: "cash-on-delivery" }}
        >
          <div className="col-span-1">
            <Form.Item
              className="w-full"
              name="name"
              label={
                <div className="flex flex-row gap-1">
                  <span className="font-semibold">Họ và tên</span>
                  <span className="text-red-500">*</span>
                </div>
              }
            >
              <Input className="w-full" placeholder="Tên của bạn" required />
            </Form.Item>
            <Form.Item
              name="phone"
              label={
                <div className="flex flex-row gap-1">
                  <span className="font-semibold">Số điện thoại</span>
                  <span className="text-red-500">*</span>
                </div>
              }
            >
              <Input
                className="w-full"
                placeholder="Nhập số điện thoại"
                required
              />
            </Form.Item>
            <Form.Item
              name="address"
              label={
                <div className="flex flex-row gap-1">
                  <span className="font-semibold">Địa chỉ giao hàng</span>
                  <span className="text-red-500">*</span>
                </div>
              }
            >
              <Input
                className="w-full"
                placeholder="Địa chỉ giao hàng"
                required
              />
            </Form.Item>
            <Form.Item
              label={
                <div className="flex flex-row gap-1">
                  <span className="font-semibold">Email</span>
                  <span className="text-red-500">*</span>
                </div>
              }
            >
              <Input
                className="w-full"
                placeholder="email@gmail.com"
                required
              />
            </Form.Item>
          </div>

          <div className="col-span-1">
            <Form.Item
              label={
                <div className="flex flex-row gap-1">
                  <span className="font-semibold">Ghi chú về đơn hàng</span>
                </div>
              }
            >
              <Input.TextArea
                rows={9}
                className="w-full"
                placeholder="Ghi chú về đơn hàng, ví dụ: lưu ý khi giao hàng."
              />
            </Form.Item>
            <Form.Item
              name="payment_method"
              label={
                <div className="flex flex-row gap-1">
                  <span className="font-semibold">Phương thức thanh toán</span>
                  <span className="text-red-500">*</span>
                </div>
              }
            >
              <Select className="w-full">
                <Select.Option value="cash-on-delivery">
                  Thanh toán khi giao hàng
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={handleSubmit}
                className="bg-custom-red-500 flex items-center justify-center text-lg text-white px-6 py-6 rounded focus:outline-none"
              >
                Thanh toán
              </Button>
            </Form.Item>
          </div>
        </Form>

        <div className="h-fit bg-white px-[20px] py-[30px] min-[1200px]:w-fit min-[1200px]:flex min-[1200px]:flex-col">
          <p className="text-xl font-bold mb-[30px]">CHI TIẾT ĐƠN HÀNG</p>
          <div className="h-fit overflow-x-auto">
            <table className="w-[1000px] border-collapse">
              <thead>
                <tr className="border-b border-t border-gray-300 bg-[#EEEEEE]">
                  <th className="p-2 border-r border-l border-gray-300">
                    Hình ảnh
                  </th>
                  <th className="p-2 border-r border-gray-300 w-2/6">
                    Sản phẩm
                  </th>
                  <th className="p-2 border-r border-gray-300">Số lượng</th>
                  <th className="p-2 border-r border-gray-300">Tạm tính</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((product, index) => (
                  <tr
                    key={product.id}
                    className={`border-b ${
                      index % 2 === 1 ? "bg-[#EEEEEE]" : "bg-white"
                    }`}
                  >
                    <td className="py-2 border-r border-gray-300 border-l flex items-center justify-center">
                      <img
                        src={product.image}
                        alt={product.name}
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td className="p-2 border-r border-gray-300">
                      {product.name}
                    </td>
                    <td className="p-2 border-r border-gray-300">
                      <div className=" flex items-center justify-center">
                        <div className="bg-white ml-2 py-2 w-[50px] text-center rounded-lg border border-black">
                          {product.quantity}
                        </div>
                      </div>
                    </td>
                    <td className="p-2 border-r border-gray-300 text-center">
                      {formatPrice(product.price * product.quantity)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="p-6 flex justify-end items-end">
            <div className="text-right grid grid-cols-2 gap-1">
              <p>Tổng tiền {totalQuantity} sản phẩm:</p>
              <p>{formatPrice(getTotalPrice())}</p>
              <p className="text-red-500">Giảm giá:</p>
              <p className="text-red-500">-0₫</p>
              <p className="font-bold">Tổng cộng:</p>
              <p className="font-bold">{formatPrice(getTotalPrice())}</p>
            </div>
          </div>
        </div>

        <div className="mt-[30px] h-fit w-full text-lg flex flex-row justify-between">
          <Link to={PATH_NAME.CARTS}>
            <button className="bg-green-500 text-white px-4 py-2 rounded focus:outline-none">
              Chỉnh sửa giỏ hàng
            </button>
          </Link>
        </div>
      </div>
      {showPopup && <Popup />}
    </div>
  );
};

export default PaymentPage;
