import {
  MenuFoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import NavBar from "./NavBar";
import logo0 from "assets/img/type0.png";
import logo1 from "assets/img/type1.png";
import logo2 from "assets/img/type2.png";
import logo3 from "assets/img/type3.png";
import logo4 from "assets/img/type4.png";
import logo5 from "assets/img/type5.png";
import logo6 from "assets/img/type6.png";
import axiosClient from "utils/axiosClient";
const logos = [logo0, logo1, logo2, logo3, logo4, logo5, logo6];

const { Sider } = Layout;

export default function SideBar() {
  const history = useHistory();
  let [typeProducts, setData] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get("/type-product");
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items = typeProducts.map((item, index) => ({
    key: item.id,
    icon: (
      <img
        className="w-[25px] h-[25px]"
        src={logos[index % 7]}
        alt={item.name}
      />
    ),
    label: `${item.name}`,
  }));

  const onClick = (e: any) => {
    console.log(e.key);
    history.push(`/products?type=${e.key}`);
    // window.location.reload();
    // if(e.key == 0){
    //   history.push(PATH_NAME.PRODUCTS)
    // }else {
    //   history.push(`/products?typeProduct=${e.key}`);
    // }
  };
  return (
    
    <div className="w-[300px] h-fit grid-cols-1 border-[2px] rounded-[10px] border-solid border-[#dec] overflow-auto">
      <div className="flex item-center py-3 px-3 gap-3 text-base bg-custom-red-500 text-white">
        <MenuFoldOutlined className="text-[20px]" />
        <p className="text-[20px]">DANH MỤC SẢN PHẨM</p>
      </div>
      <Menu
        onClick={onClick}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        items={items}
      />
      <div className="md:hidden block bg-[#f3f4f6]">
        <NavBar></NavBar>
      </div>
    </div>
  );
}
