import MainLayout from "./MainLayout";
import SideBar from "./SideBar";

type Props = {
  children?: React.ReactNode;
};

export default function ProductLayout({ children }: Props) {
  return (
    <MainLayout>
      <div className="w-full h-full bg-white overscroll-none flex flex-row gap-8 border-none">
        <div className="sticky top-0 md:block hidden h-full">
          <SideBar />
        </div>
        {children}
      </div>
    </MainLayout>
  );
}
