import logo from "assets/img/product.png";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { productList } from "constants/products";
import ProductPage from "pages/products";
import { useCart } from "components/ui/CartContext";
import {
  LoadingOutlined,
  PhoneFilled,
  CheckOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import axiosClient from "utils/axiosClient";
import { PHONE_NUMBER } from "constants/common";

type Props = {};

function formatPrice(price: number): string {
  return price
    .toLocaleString("vi-VN", { style: "currency", currency: "VND" })
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default function ProductDetail({}: Props) {
  const { id } = useParams<{ id: string }>();
  let [productDetail, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  let [numProducts, setNumProducts] = useState(0);
  let [curImg, setCurImg] = useState(0);
  let [curPreview, setCurPreview] = useState(0);
  const { addToCart } = useCart();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(`/product/${id}`);
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const decreaseNumProducts = () => {
    if (numProducts > 0) {
      setNumProducts(numProducts - 1);
    }
  };

  const increaseNumProducts = (maxValue: number) => {
    if (numProducts < maxValue) {
      setNumProducts(numProducts + 1);
    }
  };

  const setSelectedImage = (newImg: number) => {
    if (newImg != curImg) {
      setCurImg(newImg);
    }
  };

  const prePreview = () => {
    if (curPreview > 0) {
      setCurPreview(curPreview - 1);
    }
  };

  const nextPreview = () => {
    if (curPreview + 4 < productDetail.images.length) {
      setCurPreview(curPreview + 1);
    }
  };

  if (isLoading) {
    return (
      <div className="w-full flex flex-col items-center text-xl justify-center">
        <LoadingOutlined></LoadingOutlined>
        <div>Loading...</div>
      </div>
    );
  }

  console.log(productDetail);
  // productDetail = productList[1][0];

  return (
    <div className="w-full h-full flex flex-col flex-1">
      <div className="w-full h-full flex min-[1130px]:flex-row flex-col justify-between">
        <div className="w-4/5">
          <div className="h-[450px] flex items-center mb-4">
            <img
              src={productDetail.images[curImg]}
              className="w-full h-full object-contain"
              alt="logo"
            />
          </div>
          <div className="flex text-gray-300">
            <LeftOutlined className="pointer-cursor" onClick={prePreview} />
            <ul className="flex justify-center w-full">
              {productDetail.images
                .slice(curPreview, curPreview + 4)
                .map((img: string, index: number) => (
                  <li
                    key={index}
                    className={` transition-transform duration-3000 ease-in-out mx-2 max-w-[80px] flex items-center justify-center ${index + curPreview === curImg ? "border-[2px] border-blue-500" : ""}`}
                  >
                    <img
                      src={img}
                      className="w-full h-[80px] object-fill"
                      alt={`Product Image ${index}`}
                      onClick={() => setSelectedImage(index + curPreview)}
                    />
                  </li>
                ))}
            </ul>
            <RightOutlined className="pointer-cursor" onClick={nextPreview} />
          </div>
        </div>

        <div className="w-20"></div>

        <div className="w-full h-full flex flex-col pr-8">
          <p className="text-4xl text-custom-red-500">{productDetail.name}</p>
          <div className="mt-5 text-base">
            {productDetail.description.split("\n").map((line: string) => (
              <p>{line}</p>
            ))}
          </div>

          <div className="flex text-3xl flex-col justify-between mt-5">
            <div className="flex items-end">
              <p className="text-base mr-2">Giá khuyến mãi: </p>
              <span className="text-[#C7140F]">
                {formatPrice(productDetail.salePrice)}
              </span>
            </div>
            <div className="flex items-end">
              <p className="text-base mr-2">Giá bán: </p>
              <del className="text-[#777777]">
                {formatPrice(productDetail.originPrice)}
              </del>
            </div>
          </div>

          <div className="h-[1px] w-full bg-gray-500 mt-5"></div>

          <div className="mt-5 w-full flex min-[425px]:flex-row flex-col gap-[15px] justify-between text-xl">
            <div className="flex">
              <button
                className="w-10 h-12 bg-gray-300"
                onClick={decreaseNumProducts}
              >
                -
              </button>
              <div className="w-10 h-12 flex justify-center items-center border-y-2 border-gray-300">
                {numProducts}
              </div>
              <button
                className="w-10 h-12 bg-gray-300"
                onClick={() => increaseNumProducts(productDetail.remain)}
              >
                +
              </button>
            </div>

            <div
              className="w-2/5 min-w-fit px-[5px] h-12 bg-[#C7140F] text-lg text-white flex items-center justify-center cursor-pointer"
              onClick={() =>
                addToCart({
                  image: productDetail.images[0],
                  id: productDetail.id,
                  name: productDetail.name,
                  price: productDetail.salePrice,
                  quantity: numProducts,
                })
              }
            >
              THÊM VÀO GIỎ HÀNG
            </div>
          </div>
          
          <a href={`tel:${PHONE_NUMBER}`} target="_blank" className="cursor-pointer">
          <div className="mt-5 w-full h-8 bg-[#C7140F] text-lg text-white flex items-center justify-center rounded">
            <PhoneFilled className="mr-1 scale-x-[-1]"></PhoneFilled>Gọi đặt mua
            24/7
          </div>
          </a>

          <div className="mt-5 w-full text-base text-black">
            <div>
              <CheckOutlined /> Cam Kết Hàng Chính Hãng 100%
            </div>
            <div>
              <CheckOutlined /> Chuyên Gia Tư Vấn Tận Tình, Cụ Thể
            </div>
            <div>
              <CheckOutlined /> Giá Ưu Đãi Tốt Nhất Thị Trường
            </div>
            <div>
              <CheckOutlined /> COD (Nhận Hàng Thanh Toán Tiền)
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 mb-8">
        <p>Thông tin sản phẩm</p>
        <div className="w-full h-[1px] bg-gray-300 mb-4"></div>

        <p className="text-[#C7140F] text-xl text-center font-bold leading-loose">
          {productDetail.name}
        </p>

        {productDetail.info && (
          <>
            <p className="text-[#C7140F] font-bold leading-loose">
              Thông tin sản phẩm
            </p>
            {productDetail.info
              .split("\n")
              .map((line: string, index: number) => (
                <p key={index} className="leading-loose">
                  {line}
                </p>
              ))}
          </>
        )}

        {productDetail.effect && (
          <>
            <p className="text-[#C7140F] font-bold leading-loose">Công dụng</p>
            {productDetail.effect
              .split("\n")
              .map((line: string, index: number) => (
                <p key={index} className="leading-loose">
                  {line}
                </p>
              ))}
          </>
        )}

        {productDetail.guide && (
          <>
            <p className="text-[#C7140F] font-bold leading-loose">Cách dùng</p>
            {productDetail.guide
              .split("\n")
              .map((line: string, index: number) => (
                <p key={index} className="leading-loose">
                  {line}
                </p>
              ))}
          </>
        )}

        {productDetail.objectOfUse && (
          <>
            <p className="text-[#C7140F] font-bold leading-loose">
              Đối tượng sử dụng
            </p>
            {productDetail.objectOfUse
              .split("\n")
              .map((line: string, index: number) => (
                <p key={index} className="leading-loose">
                  {line}
                </p>
              ))}
          </>
        )}
      </div>

      <ProductPage initTitle="Sản phẩm tương tự" type="demo" />
    </div>
  );
}
