import TitleLine from 'components/ui/TitleLine';

export default function IntroducePage() {
  return (
    <div>
      <div className="flex w-full justify-center">
        <div className="flex md:w-2/3 sm:w-5/6 w-full">
          <div className="flex flex-wrap bg-slate-100 ">
            <div className="p-10">
              <div className="flex flex-col">
                <TitleLine title="Giới thiệu"></TitleLine>
                <div className="p-4 leading-9">
                  <div>
                    <p>
                      <i>
                        <strong>
                          Kính gửi Quý đối tác và khách hàng thân mến!
                        </strong>
                      </i>
                    </p>
                    <p>
                      Lời đầu tiên, cho phép Sâm nấm Hàn Quốc gửi lời chào và lời
                      cảm ơn tới Quý đối tác và khách hàng đã luôn tin tưởng, đồng
                      hành và gắn bó với chúng tôi.
                    </p>
  
                    <p>
                      Nơi đây, chúng tôi chia sẻ về về Giá Trị Cốt Lõi, Sứ Mệnh &
                      Tầm Nhìn của chúng tôi.
                    </p>
                  </div>
                  <div>
                    <p className="font-bold text-blue-600 text-[18px]">
                      Giới thiệu doanh nghiệp
                    </p>
                    <p>
                      ★ Sâm nấm Hàn Quốc là một trong những doanh nghiệp hàng đầu
                      trong lĩnh vực cung cấp và phân phối các sản phẩm Nhân Sâm -
                      Nấm Linh Chi - Đông Trùng Hạ Thảo Nhập Khẩu từ Hàn Quốc và
                      một số thực phẩm chức năng khác giúp tăng cường sức khỏe.
                    </p>
                    <p>
                      ★ Với mục tiêu phân phối các sản phẩm chất lượng tốt nhất
                      đến tay người tiêu dùng. Hiện nay chúng tôi đã mở rộng hệ
                      thống bán hàng tại Hà Nội, đồng thời phục vụ hàng nghìn lượt
                      khách hàng ở khắp các tỉnh thành trên Toàn Quốc.
                    </p>
                    <p className="font-bold text-blue-600 text-[18px]">
                      Giá trị cốt lõi
                    </p>
                    <p>
                      ★ <strong>Với Phương Châm: Tâm - Tin - Tầm.</strong> Chúng
                      tôi luôn lấy tôn chỉ kinh doanh từ “Tâm”, luôn cung cấp sản
                      phẩm chất lượng và uy tín thương hiệu đặt lên hàng đầu.
                      Chúng tôi cam kết đảm bảo chất lượng sản phẩm cung cấp ra
                      thị trường là hàng được nhập khẩu chính hãng.
                    </p>
                    <p className="font-bold text-blue-600 text-[18px]">
                      Tầm Nhìn Và Sứ Mệnh:
                    </p>
                    <p>
                      ★ Chúng tôi luôn nỗ lực không ngừng để mang tới cho khách
                      hàng những sản phẩm phù hợp nhất và tốt nhất trong lĩnh vực
                      thực phẩm bổ dưỡng.
                    </p>
                    <p>
                      ★ Để phục vụ người tiêu dùng tại Việt Nam có được cuộc sống
                      khỏe mạnh hơn, tiện lợi hơn. Doanh nghiệp chúng tôi cam kết
                      cung cấp:
                    </p>
                    <ul>
                      <li> ✧ Sản phẩm chất lượng cao;</li>
                      <li>✧ Dịch vụ tại gia chuyên nghiệp;</li>
                      <li>
                        ✧ Tư vấn nhiệt tình, giải đáp mọi thắc mắc về sản phẩm và
                        sức khỏe;
                      </li>
                      <li>
                        ✧ Chính sách hậu mãi tri ân khách hàng thường xuyên nhằm
                        mang đến sự hài lòng cao nhất cho khách hàng.
                      </li>
                    </ul>
                    <p>★ Thông Tin:</p>
                    <ul>
                      <li>✧ Website:</li>
                      <li>
                        ✧ Địa chỉ: số 44D ngách 85 ngõ 164 Vương Thừa Vũ, quận
                        Thanh Xuân, thành phố Hà Nội
                      </li>
                      <li>✧ Số tài khoản:</li>
                      <li>✧ Hotline: 0976661568</li>
                    </ul>
                    <p>★ Hệ Thống Cửa Hàng Của Doanh nghiệp Sâm nấm Hàn Quốc:</p>
                    <ul>
                      <li>
                        ✧ Hà Nội : số 44D ngách 85 ngõ 164 Vương Thừa Vũ, quận
                        Thanh Xuân, thành phố Hà Nội
                      </li>
                    </ul>
                  </div>
                  <div className="flex items-center justify-center mt-10">
                    <i>
                      Sâm nấm Hàn Quốc sẽ luôn là sự lựa chọn hoàn hảo cho quý vị!
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
