import Item from 'antd/es/list/Item';
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';

interface ShoppingCart {
  id: number;
  image: string;
  name: string;
  price: number;
  quantity: number;
}

interface CartContextProps {
  cart: ShoppingCart[];
  addToCart: (product: ShoppingCart) => void;
  removeFromCart: (productId: number) => void;
  getTotalPrice: () => number;
  notification: string | null;
  clearNotification: () => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cart, setCart] = useState<ShoppingCart[]>(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });
  const [notification, setNotification] = useState<string | null>(null);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product: ShoppingCart) => {
    if(product.quantity == 0) return;
    const existingProduct = cart.find(item => item.id === product.id);
    if (existingProduct) {
      setCart(
        cart.map(item =>
          item.id === product.id ? { ...item, quantity: item.quantity + product.quantity } : item
        )
      );
    } else {
      setCart([...cart, { ...product, quantity: product.quantity }]);
    }
    setNotification(`${product.name} đã được thêm vào giỏ hàng`);
  };

  const removeFromCart = (productId: number) => {
    const updatedCart = cart.map(item =>
      item.id === productId ? { ...item, quantity: item.quantity - 1 } : item
    ).filter(item => item.quantity > 0);
    setCart(updatedCart);
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const clearNotification = () => {
    setNotification(null);
  };

  const contextValue = {
    cart,
    addToCart,
    removeFromCart,
    getTotalPrice,
    notification,
    clearNotification,
  };

  return (
    <CartContext.Provider value={contextValue}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
