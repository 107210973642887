export const NAVBAR_ITEMS = [
  {
    title: 'Trang chủ',
    link: '/',
  },
  {
    title: 'Sản phẩm',
    link: '/products',
  },
  {
    title: 'Giới thiệu',
    link: '/introduces',
  },
  {
    title: 'Tin tức',
    link: '/news',
  },
  {
    title: 'Chính sách',
    link: '/policy',
  },
  {
    title: 'Liên hệ',
    link: '/contacts',
  }
];

export const PHONE_NUMBER = "0976661568";
export const ZALO_NUMBER = "0976661568";
export const FB_LINK = "https://www.facebook.com/Nanaxinhtuoi?mibextid=LQQJ4d";
export const EMAIL_ADDRESS = "giangtran.hvtck42@gmail.com"
