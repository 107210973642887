import titleLogo from "assets/img/logo_new.png";
import ContactForm from "components/contacts/ContactForm";
import TitleLine from "components/ui/TitleLine";

export default function ContactPage() {
  return (
    <div className="flex w-full justify-center">
      <div className="flex md:w-2/3 sm:w-5/6 w-full">
        <div className="flex flex-wrap w-full bg-slate-100 ">
          <div className="p-10 w-full">
            <div className="w-full">
              <TitleLine title="Liên hệ"></TitleLine>
              <div className="mt-[20px]">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
