import { HomeFilled, MailFilled } from '@ant-design/icons';
import { Button, Input } from 'antd';
import logo from 'assets/img/logo.png';
import { EMAIL_ADDRESS, PHONE_NUMBER } from 'constants/common';
import {
  FaFacebook,
  FaFacebookMessenger,
  FaMapMarker,
  FaPhone,
  FaSms,
} from 'react-icons/fa';
import { FiYoutube } from 'react-icons/fi';
import { IoLogoInstagram } from 'react-icons/io';
import { SiGmail, SiZalo } from 'react-icons/si';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="md:relative mt-[40px]">
      <div className="bg-[#8B0000] py-[20px] px-[60px] w-full h-full block">
        <div className="flex sm:justify-between justify-center items-center sm:flex-row flex-col flex-1 pb-2 border-solid border-b-[1px] border-[#ccc]">
          <p className="md:text-[26px] text-[18px] uppercase text-white ">
            SÂM NẤM HÀN QUỐC
          </p>
          <div className="flex gap-8 sm:flex-row flex-col items-center">
            <p className="uppercase text-[14px] md:text-[20px] text-white">
              ĐĂNG KÝ NHẬN KHUYẾN MÃI
            </p>
            <div className="flex sm:flex-row flex-col items-center gap-1 sm:gap-0">
              <Input
                placeholder="Nhập email của bạn"
                className="w-[200px] sm:w-[300px]"
              />
              <Button className="px-4 font-light text-[16px] bg-[#ccc]">
                Đăng ký
              </Button>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col justify-between items-start pt-6 gap-3">
          <div className="">
            <img
              src={logo}
              alt="logo"
              className="w-[140px] h-[140px] rounded-full"
            />
          </div>
          <div className=''>
            <h1 className="text-white lg:text-[16px] text-[18px] uppercase text-bold mb-[5px]">
              Thông tin liên hệ
            </h1>
            <div className="flex flex-row gap-2 text-white mb-[5px]">
              <HomeFilled className="" />
              <span className="text-base">SÂM NẤM HÀN QUỐC</span>
            </div>
            <div>
              <div className="flex flex-row gap-2 text-white mb-[5px]">
                <FaMapMarker />
                <p className="text-base">
                  Địa chỉ: 44D ngách 85 ngõ 164 Vương Thừa Vũ quận Thanh Xuân Hà Nội
                </p>
              </div>
              <div className="flex flex-row gap-2 text-white mb-[5px]">
                <FaPhone/>
                <p className="text-base">Số điện thoại: {PHONE_NUMBER}</p>
              </div>
            </div>
            <div className="flex flex-row gap-2 text-white">
              <MailFilled />
              <p className="flex-wrap">Email: {EMAIL_ADDRESS}</p>
            </div>
          </div>

          <div className="flex flex-col text-white">
            <h1 className="lg:text-[16px] text-[18px] uppercase text-bold">
              Hỗ trợ mua hàng
            </h1>
            <Link to="/" className="text-base mt-[5px]">
              Trang chủ
            </Link>
            <Link to="/products" className="text-base mt-[5px]">
              Sản phẩm
            </Link>
            <Link to="/news" className="text-base mt-[5px]">
              Tin tức
            </Link>
          </div>

          <div className="flex flex-col text-white">
            <h1 className="lg:text-[16px] text-[18px] uppercase text-bold">
              Giới thiệu
            </h1>
            <Link to="/policy" className="text-base mt-[5px]">
              Chính sách
            </Link>
            <Link to="/introduces" className="text-base mt-[5px]">
              Giới thiệu
            </Link>
            <Link to="/contacts" className="text-base mt-[5px]">
              Liên hệ
            </Link>
          </div>

          <div className="flex flex-col gap-2 text-white">
            <img src={logo} className="w-[200px] h-[200px]" alt="logo" />
            <div className="flex flex-row w-full gap-2 justify-center">
              <a href={`https://www.facebook.com/Nanaxinhtuoi?mibextid=LQQJ4d`} target="_blank" className='cursor-pointer'>
              <div className="p-1 border-solid border-[1px] rounded-md border-[#6c6c6c]">
                <FaFacebook />
              </div>
              </a>
              <div className="p-1 border-solid border-[1px] rounded-md border-[#6c6c6c]">
                <SiGmail />
              </div>
              <div className="p-1 border-solid border-[1px] rounded-md border-[#6c6c6c]">
                <IoLogoInstagram />
              </div>
              <div className="p-1 border-solid border-[1px] rounded-md border-[#6c6c6c]">
                <FiYoutube />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed right-0 left-0 px-[40px] bg-white py-[4px] shadow-lg border-[1px] border-t-[#ccc] lg:hidden block bottom-0">
        <div className="flex flex-row justify-between mx-[-16px] sm:mx-0">
          <div className="flex flex-col items-center justify-center cursor-pointer">
            <div className="p-1 rounded-full border-[2px]">
              <FaPhone className=" text-red-500 text-[14px]" />
            </div>
            <a href={`tel:${PHONE_NUMBER}`} target="_blank" className="text-[12px] md:text-[14px]">
              Gọi điện
            </a>
          </div>
          <div className="flex flex-col items-center justify-center cursor-pointer">
            <div className="p-1 rounded-full border-[2px]">
              <FaSms className=" text-red-500 text-[14px]" />
            </div>
            <a href={`https://zalo.me/${PHONE_NUMBER}`} target="_blank" className="text-[12px] md:text-[14px]">Nhắn tin</a>
          </div>
          <div className="flex flex-col items-center justify-center cursor-pointer">
            <div className="p-1 rounded-full border-[2px]">
              <SiZalo className=" text-red-500 text-[14px]" />
            </div>
            <a href={`https://zalo.me/${PHONE_NUMBER}`} target="_blank" className="text-[12px] md:text-[14px]">Zalo</a>
          </div>
          <div className="flex flex-col items-center justify-center cursor-pointer">
            <div className="p-1 rounded-full border-[2px]">
              <FaFacebookMessenger className=" text-red-500 text-[14px]" />
            </div>
            <a href={`https://www.facebook.com/Nanaxinhtuoi?mibextid=LQQJ4d`} target="_blank" className="text-[12px] md:text-[14px]">Messenger</a>
          </div>
        </div>
      </div>
    </div>
  );
}
