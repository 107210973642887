import logo from "assets/img/product.png";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { useCart } from "components/ui/CartContext";
import axiosClient from "utils/axiosClient";

function formatPrice(price: number): string {
  return price
    .toLocaleString("vi-VN", { style: "currency", currency: "VND" })
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export default function SearchPage() {
  let [products, setData] = useState<any[]>([]);
  let [limit, setLimit] = useState(0);
  let [total, setTotal] = useState(0);

  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const pageParam = query.page;
  const page = typeof pageParam === "string" ? parseInt(pageParam, 10) : 1;

  const pageSearch = query.search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(
          `/product?search=${pageSearch}&page=${page}&limit=12`
        );
        setData(response.data.docs);
        setLimit(response.data.totalPages);
        setTotal(response.data.totalDocs);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const { addToCart } = useCart();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [page]);

  const productClick = (productId: string) => {
    history.push(`/products/${productId}`);
    window.location.reload();
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber == page) return;
    history.push(`/search?search=${pageSearch}&page=${pageNumber}&limit=12`);
    window.location.reload();
  };

  return (
    <div className="w-full flex-1">
      {typeof pageSearch === "string" && (
        <p className="mb-6 text-xl font-bold">
          {total} kết quả tìm kiếm với từ khóa "{pageSearch}"
        </p>
      )}
      <div className="grid lg:grid-cols-4 phone:grid-cols-2 grid-cols-1">
        {products.map((product: any) => (
          <div className="min-w-min w-full h-fit min-h-96 flex flex-col gap-4 px-8 text-base">
            <img
              src={product.images[0]}
              className="h-1/2 cursor-pointer"
              alt="logo"
              onClick={() => productClick(product.id)}
            />
            <div className="h-full flex flex-col justify-between gap-2">
              <p
                className="cursor-pointer"
                onClick={() => productClick(product.id)}
              >
                {product.name}
              </p>

              <div className="flex flex-row justify-between">
                <div>
                  <span className="text-red-500">
                    {formatPrice(product.salePrice)}
                  </span>{" "}
                  <br />
                  <del className="text-gray">
                    {formatPrice(product.originPrice)}
                  </del>
                </div>

                <p
                  className="h-6 w-6 text-2xl text-red-500 rounded-full border-2 
                  border-red-500 flex items-center justify-center cursor-pointer"
                  onClick={() =>
                    addToCart({
                      image: product.images[0],
                      id: product.id,
                      name: product.name,
                      price: product.salePrice,
                      quantity: 1,
                    })
                  }
                >
                  +
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center my-4">
        {Array.from({ length: limit }, (_, i) => i + 1).map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            className={`
                mx-2 px-3 py-1 rounded-full transition-colors
                ${
                  pageNumber === page
                    ? "bg-red-500 text-white"
                    : "hover:bg-gray-300 hover:text-gray-700"
                }
              `}
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </div>
  );
}
