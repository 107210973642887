import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

type NavBarItemProps = {
  link: string;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
};

export default function NavBarItem({
  link,
  label,
  isActive,
  onClick,
}: NavBarItemProps) {
  return (
    <motion.div
      initial={{
        borderBottom: isActive ? '4px solid #972711' : '4px solid transparent',
      }}
      animate={{
        borderBottom: isActive ? '4px solid #972711' : '4px solid transparent',
      }}
      transition={{
        duration: 0.5,
      }}
      className="h-full flex items-center justify-center"
    >
      <Link to={link} className={`text-white`}>
        <p className="text-base text-black white-space-nowrap">{label}</p>
      </Link>
    </motion.div>
  );
}
