import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PATH_NAME } from 'constants/pathName';

interface NotificationProps {
  message: string;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 5000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="fixed min-[550px]:w-[500px] min-[350px]:w-[300px] w-[250px] top-4 right-4 bg-white border border-black rounded-lg shadow-lg p-4 z-50">
      <div className="flex justify-between items-center">
        <div className="text-lg font-bold">Thêm vào giỏ hàng</div>
        <button onClick={onClose} className="focus:outline-none">
          <div className="h-5 w-5 text-gray-500">
            X
          </div>
        </button>
      </div>
      <div className="mt-2">{message}</div>
      <div className="mt-4 flex justify-end">
        <Link to={PATH_NAME.CARTS}>
        <button className="bg-green-500 text-white px-4 py-2 rounded focus:outline-none">
          Thanh toán
        </button>
        </Link>
      </div>
    </div>
  );
};

export default Notification;
