import banner from 'assets/img/banner.png'
type Props = {}

export default function Carousel() {
  return (
    <div className="mb-[50px]">
      <img src={banner} alt="logo" className="w-full"/>
    </div>
  );
}
