import { NAVBAR_ITEMS } from 'constants/common';

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavBarItem from './NavBarItem';

export default function NavBar() {
  const pathName = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    '/' + pathName?.pathname.split('/')[1],
  );

  useEffect(() => {
    if (activeTab !== '/' + pathName?.pathname.split('/')[1]) {
      setActiveTab('/' + pathName?.pathname.split('/')[1]);
    }
  }, [pathName.pathname]);

  return (
    <div className="flex gap-8 md:flex-row flex-col h-full">
      {NAVBAR_ITEMS.map((item: any, index: any) => (
        <div key={item.link} onClick={() => setActiveTab(item.link)}>
          <NavBarItem
            link={item.link}
            label={item.title}
            isActive={activeTab === item.link}
          />
        </div>
      ))}
    </div>
  );
}
