import { Route, Switch } from 'react-router-dom';

import 'assets/styles/custom.css';
import 'assets/styles/global.css';
import ProductLayout from 'components/layout/ProductLayout';
import NotFoundPage from 'components/not-found';
import ProductDetail from 'components/products/ProductDetail';
import { PATH_NAME } from 'constants/pathName';
import { default as MainLayout } from './components/layout/MainLayout';
import ContactPage from './pages/contacts';
import HomePage from './pages/home';
import IntroducePage from './pages/introduces';
import NewsPage from './pages/news';
import ProductPage from './pages/products';
import SalePage from './pages/sales';
import CartPage from 'pages/carts';
import Notification from 'components/ui/Notification';
import { useCart } from 'components/ui/CartContext';
import NewsDetail from 'components/news/NewsDetail';
import PaymentPage from 'pages/payment';
import SearchPage from 'pages/search';
import PolicyPage from 'pages/policy';

function App() {
  const { notification, clearNotification } = useCart();
  return (
    <div className="App h-full">
      {notification && <Notification message={notification} onClose={clearNotification} />}
      <Switch>
        <Route path={PATH_NAME.HOME} exact>
          <ProductLayout>
            <HomePage />
          </ProductLayout>
        </Route>
        <Route path={PATH_NAME.PRODUCTS} exact>
          <ProductLayout>
            <ProductPage/>
          </ProductLayout>
        </Route>
        <Route path={PATH_NAME.NEWS} exact>
          <ProductLayout>
            <NewsPage />
          </ProductLayout>
        </Route>
        <Route exact path={`${PATH_NAME.NEWS}/:id`}>
          <ProductLayout>
            <NewsDetail />
          </ProductLayout>
        </Route>
        <Route path={PATH_NAME.POLICY} exact>
          <ProductLayout>
            <PolicyPage />
          </ProductLayout>
        </Route>

        <Route exact path={`${PATH_NAME.PRODUCTS}/:id`}>
          <ProductLayout>
            <ProductDetail />
          </ProductLayout>
        </Route>
        <Route path={PATH_NAME.CONTACTS} exact>
          <MainLayout>
            <ContactPage />
          </MainLayout>
        </Route>
        <Route path={PATH_NAME.SALES} exact>
          <MainLayout>
            <SalePage />
          </MainLayout>
        </Route>
        <Route path={PATH_NAME.INTRODUCES} exact>
          <MainLayout>
            <IntroducePage />
          </MainLayout>
        </Route>
        <Route path={PATH_NAME.CARTS} exact>
          <MainLayout>
            <CartPage />
          </MainLayout>
        </Route>
        <Route path={PATH_NAME.PAYMENT} exact>
          <MainLayout>
            <PaymentPage />
          </MainLayout>
        </Route>
        <Route path={PATH_NAME.SEARCH} exact>
          <MainLayout>
            <SearchPage />
          </MainLayout>
        </Route>
        <Route path="">
          <MainLayout>
            <NotFoundPage />
          </MainLayout>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
