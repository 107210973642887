import { Button } from "antd";
import Carousel from "components/ui/Carousel";
import TitleLine from "components/ui/TitleLine";
import { PATH_NAME } from "constants/pathName";
import NewsPage from "pages/news";
import ProductPage from "pages/products";
import { useHistory } from "react-router-dom";
type Props = {};

export default function HomePage({ }: Props) {
  const history = useHistory();

  const handleClick = (path: string) => {
    history.push(path)
  };
  
  return (
    <div className="flex-1 flex flex-col items-center h-full bg-white">
      <Carousel />
      <ProductPage initTitle="Tất cả sản phẩm" type="demo"/>
      <button onClick={() => handleClick(PATH_NAME.PRODUCTS)}
        className="w-fit h-fit bg-custom-red-500 text-white text-center 
        rounded-2xl text-[20px] px-[30px] py-[10px] mt-[40px] mb-[40px] hover:bg-custom-red-300">
        Xem thêm
      </button>
      <NewsPage type="demo"/>
      <button onClick={() => handleClick( PATH_NAME.NEWS)}
        className="w-fit h-fit bg-custom-red-500 text-white text-center 
        rounded-2xl text-[20px] px-[30px] py-[10px] mt-[40px] hover:bg-custom-red-300">
        Xem thêm
      </button>
    </div>
  );
}
