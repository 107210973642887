import { Link } from 'react-router-dom';

type TypeProps = {
  srcImg: string;
  srcHref: string;
  TypeTitle: string;
  TypeDesc: string;
};

export default function Type({
  srcImg,
  srcHref,
  TypeTitle,
  TypeDesc,
}: TypeProps) {
  return (
    <div className="flex my-3">
      <div className="w-1/3 ">
        <Link to={srcHref} title={TypeTitle}>
          <img
            src={srcImg}
            className=""
            alt={TypeTitle}
            style={{ minWidth: '100%' }}
          />
        </Link>
      </div>
      <div className="w-1/2 ml-9">
        <Link to={srcHref} title={TypeTitle}>
          <h3 className="text-xl font-bold mb-3 hover:text-[#8B0000]">
            {TypeTitle}
          </h3>
        </Link>
        <p className="text-sm text-[#666]">{TypeDesc}</p>
      </div>
    </div>
  );
}
