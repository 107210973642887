import { Button, Form, Input } from 'antd';

export default function ContactForm() {

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      layout="vertical"
      className="w-full"
      requiredMark={false}
    >
      <Form.Item
        className="w-full"
        label={
          <div className="flex flex-row gap-1">
            <span className="font-semibold">Họ và tên
            </span>
            <span className='text-red-500'>*</span>
          </div>
        }
      >
        <Input
          className="w-full"
          placeholder="Tên của bạn"
        />
      </Form.Item>
      <Form.Item
        label={
          <div className="flex flex-row gap-1">
            <span className="font-semibold">Email</span>
            <span className='text-red-500'>*</span>
          </div>
        }
      >
        <Input
          className="w-full"
          placeholder="email@gmail.com"
          required
        />
      </Form.Item>
      <Form.Item
        label={
          <div className="flex flex-row gap-1">
            <span className="font-semibold">Số điện thoại</span>
            <span className='text-red-500'>*</span>
          </div>
        }
      >
        <Input
          className="w-full"
          placeholder="Nhập số điện thoại"
          required
        />
      </Form.Item>
      <Form.Item
        label={
          <div className="flex flex-row gap-1">
            <span className="font-semibold">Tiêu đề</span>
            <span className='text-red-500'>*</span>
          </div>
        }
      >
        <Input
          className="w-full"
          placeholder="Tiêu đề"
        />
      </Form.Item>
      <Form.Item
        label={
          <div className="flex flex-row gap-1">
            <span className="font-semibold">Nội dung</span>
            <span className='text-red-500'>*</span>
          </div>
        }
      >
        <Input.TextArea
          rows={6}
          className="w-full"
          placeholder="Chi tiết nội dung liên hệ"
        />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType='submit'
          className="w-[200px] flex items-center justify-center p-2 bg-[#8B0000] text-white py-3 rounded-lg"
        >
          Gửi
        </Button>
        </Form.Item>
    </Form>
  )
}