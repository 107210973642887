export const PATH_NAME = {
  HOME: '/',
  PRODUCTS: '/products',
  NEWS: '/news',
  CONTACTS: '/contacts',
  SALES: '/sales',
  INTRODUCES: '/introduces',
  ACCOUNT_MANAGEMENT: '/account-management',
  CARTS: '/cart',
  PAYMENT: '/payment',
  SEARCH: '/search',
  POLICY: '/policy',
};
