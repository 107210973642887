import titleLogo from 'assets/img/logo_new.png';

type Props = {
    title?: string
}

export default function TitleLine({ title }: Props) {
  return (
    <div className="w-full mb-[50px]">
        <div className="w-fit h-fit px-[20px] py-[5px] flex flex-row gap-2 items-center bg-custom-red-500 text-white text-xl rounded-t-xl ml-[40px]">
          <img
            src={titleLogo}
            className="h-[40px]"
            alt="Sâm Nấm Hàn Quốc"
          />
          {title}
        </div>
        <div className='w-full h-1 bg-custom-red-500'></div>
    </div>
  )
}