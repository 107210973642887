import {
  MailOutlined,
  MenuFoldOutlined,
  PhoneOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Input } from 'antd';
import logo from 'assets/img/logo.png';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { SiZalo } from 'react-icons/si';
import NavBar from './NavBar';

import { useMediaQuery } from 'react-responsive';
import Button from '../ui/Button';
import Footer from './Footer';
import SideBar from './SideBar';
import { Link, useHistory } from 'react-router-dom';
import { PATH_NAME } from 'constants/pathName';
import { useCart } from 'components/ui/CartContext';
import { EMAIL_ADDRESS, PHONE_NUMBER } from 'constants/common';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isHover, setIsHover] = useState(false);
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const { cart } = useCart();
  const totalQuantity = cart.reduce((total, item) => total + 1, 0);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    history.push(`/search?search=${searchTerm}`);
    window.location.reload();
  };

  const handleSearchClick = () => {
    history.push(`/search?search=${searchTerm}`);
    window.location.reload();
  };

  const isTablet = useMediaQuery({
    query: '(min-width: 1030px)',
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1030px)',
  });

  return (
    <div className="w-full h-full">

      <div className="w-full h-[140px] md:h-[120px] bg-custom-red-500 md:px-[60px] px-[40px] md:py-[20px] py-[10px]">
        <div className="flex flex-row h-full items-center gap-4">
          <div className="flex flex-1 w-full gap-4 items-center">
            <div className={`md:hidden block ${isHover && 'hidden md:block'}`}>
              <motion.div
                onFocus={() => setIsOpenSideBar(true)}
                onBlur={() => setIsOpenSideBar(false)}
                onClick={() => setIsOpenSideBar(!isOpenSideBar)}
              >
                <MenuFoldOutlined
                  className={`text-white text-[20px] md:text-[28px] cursor-pointer`}
                />
                {isOpenSideBar && (
                  <div className="z-[100] fixed top-0 left-0">
                    <SideBar />
                  </div>
                )}
              </motion.div>
            </div>
            <div className='flex flex-col sm:flex-row'>
            <img src={logo} alt="logo" className="rounded-full md:w-[100px] md:h-[100px] h-[60px] w-[60px]" />
            <div className="flex flex-row items-center w-full gap-4">
              <div className='lg:flex hidden flex-row gap-2 justify-center text-white text-lg'>
                <div className='flex gap-2'>
                  <PhoneOutlined/>
                  <span>{PHONE_NUMBER}</span>
                </div>
                <span>|</span>
                <div className='flex gap-2'>
                  <MailOutlined/>
                  <span>{EMAIL_ADDRESS}</span>
                </div>
              </div>
              <form onSubmit={handleSubmit} className="flex flex-row items-center justify-start w-full">
                <motion.div
                  className={`h-[40px] sm:flex border-none rounded-tl-sm rounded-bl-sm`}
                  onFocus={() => setIsHover(true)}
                  onBlur={() => setIsHover(false)}
                  initial={{
                    width: isHover
                      ? '100%'
                      : isDesktopOrLaptop
                        ? '400px'
                        : isTablet ? '300px' :
                          isMobile
                            ? '230px'
                            : '100px'
                  }}
                  animate={{
                    width: isHover ? '100%' : isDesktopOrLaptop
                      ? '400px'
                      : isTablet
                        ? '230px'
                        : '100px',
                  }}
                  whileFocus={{
                    transition: {
                      duration: 0.5,
                    },
                  }}
                >
                  <Input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Tìm kiếm"
                    className="h-full rounded-tr-none rounded-br-none placeholder:text-[#ccc]"
                  />
                </motion.div>
                <div onClick={handleSearchClick} className="flex h-[40px] items-center justify-center px-2 bg-yellow-500 rounded-tr-md rounded-br-md rounded-tl-none rounded-bl-none">
                  <SearchOutlined className="text-[24px]" />
                </div>
              </form>
            </div>
            </div>
          </div>
          <Link to={PATH_NAME.CARTS}>
          <Button
            variant="custom"
            className={`flex flex-row gap-3 sm:h-[40px] h-[24px] w-[24px] sm:w-[inherit] bg-orange-600 md:rounded-lg rounded-full hover:opacity-80 items-center sm:py-2 py-1 ${isHover && 'hidden md:flex'}`}
          >
            <p className="hidden sm:block text-base font-normal">Giỏ hàng</p>
            {/* <ShoppingCartOutlined /> */}
            <div className="relative">
              <ShoppingCartOutlined className="relative text-[24px]"/>
              {totalQuantity > 0 && (
                <div className="absolute -top-3 -right-3 bg-yellow-400 w-6 h-6 rounded-full flex items-center justify-center text-xs font-semibold text-white">
                  {totalQuantity}
                </div>
              )}
            </div>
          </Button>
          </Link>
        </div>
      </div>
      <div className="md:px-[60px] h-[60px] md:block hidden bg-[#f3f4f6]">
        <NavBar />
      </div>
      <div>
      </div>
      <div className="lg:px-[60px] phone:px-[10px] px-[30px] pt-[20px] bg-white w-full">
        {children}
      </div>
      <Footer />
      <div className="z-[9999] fixed bottom-[80px] right-0 flex flex-col gap-2">
        <a href={`tel:${PHONE_NUMBER}`} target="_blank">
        <motion.div
          className="w-[50px] bg-yellow-400 h-[50px] rounded-full flex items-center justify-center shadow-lg"
          whileHover={{
            scale: 1.4,
          }}
          initial={{
            y: 100,
          }}
          animate={{
            y: 0,
          }}
          transition={{
            duration: 0.5,
          }}
        >
          <PhoneOutlined className="text-red-500 font-semibold text-[24px]" />
        </motion.div>
        </a>
        <a href={`https://zalo.me/${PHONE_NUMBER}`} target="_blank">
        <motion.div
          className="w-[50px] h-[50px] rounded-xl shadow-2xl flex items-center justify-center"
          whileHover={{
            scale: 1.2,
          }}
          initial={{
            x: 50,
          }}
          animate={{
            x: 0,
          }}
          transition={{
            duration: 0.5,
          }}
        >
          <div className="rounded-xl border-solid border-[2px] p-2 bg-blue-600 text-white">
            <SiZalo className="text-[24px]" />
          </div>
        </motion.div>
        </a>
      </div>
    </div>
  );
};

export default MainLayout;
